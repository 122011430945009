import { App } from 'vue';
import prohibitZoomDirective from './prohibit-zoom.directive';
import autofocusDirective from './autofocus.directive';
import onErrorDirective from './on-error.directive';

export default function config(app: App): void {
    app.directive('prohibit-zoom', prohibitZoomDirective);
    app.directive('autofocus', autofocusDirective);
    app.directive('onError', onErrorDirective);
}
