<template>
    <ContextMenu
        v-for="(menu, index) in menus"
        :key="menu.id"
        :menu="menu"
        :last="index === menus.length - 1"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import contextMenuService from './contextMenu.service';

import ContextMenu from './ContextMenu.vue';

export default defineComponent({
    name: 'ContextMenuHost',
    components: {
        ContextMenu,
    },
    setup() {
        const { menus } = contextMenuService;

        return {
            menus,
        };
    },
});
</script>
