<template>
    <div class="splitpanes__pane" :class="{ active: active }" :style="style">
        <slot/>

        <div v-show="!last" class="splitpanes__splitter"></div>
    </div>
</template>

<script>
export default {
    name: 'Pane',
    inject: ['requestUpdate', 'onPaneAdd', 'onPaneRemove'],

    props: {
        size: { type: [Number, String], default: null },
        minSize: { type: [Number, String], default: 0 },
        maxSize: { type: [Number, String], default: 100 },
    },

    data: () => ({
        style: {},
        last: false,
        active: false,
    }),

    computed: {
        sizeNumber() {
            return (this.size || this.size === 0) ? parseFloat(this.size) : null;
        },
        minSizeNumber() {
            return parseFloat(this.minSize);
        },
        maxSizeNumber() {
            return parseFloat(this.maxSize);
        },
    },

    watch: {
        sizeNumber(size) {
            this.requestUpdate({ target: this, size });
        },
        minSizeNumber(min) {
            this.requestUpdate({ target: this, min });
        },
        maxSizeNumber(max) {
            this.requestUpdate({ target: this, max });
        },
    },

    mounted() {
        this.onPaneAdd(this);
    },

    beforeUnmount() {
        this.onPaneRemove(this);
    },

    methods: {
        // Called from the splitpanes component.
        update(style, last, active) {
            this.style = style;
            this.last = last;
            this.active = active;
        },
    },
};
</script>
<style lang="scss" scoped>
.splitpanes__pane {
    position: relative;
    overflow: visible;
    flex-shrink: 0;

    &:hover,
    &:focus,
    &:active,
    &.active {
        .splitpanes__splitter {
            transform: translate(50%, -50%) scaleX(3);
        }
    }

    .splitpanes__splitter {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: 1px;
        background-color: theme('colors.border');
        height: 100%;
        z-index: 1;
        cursor: col-resize;
    }
}
</style>