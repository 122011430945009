<template>
    <Modal
        v-for="(dialog, index) in dialogs"
        :key="dialog.id"
        :dialog="dialog"
        :class="{ 'pointer-events-none': dialog.minimized || index !== dialogs.length - 1 }"
        :last="index === dialogs.length - 1"/>

    <div v-if="minimizedDialogs.length > 0" class="minimized-dialogs">
        <div v-for="dialog in minimizedDialogs" :key="dialog.id" class="minimized-dialog" :title="dialog.description">
            <button 
                v-if="!dialog.options.hideMinimizeButton"
                @click.prevent="() => dialog.maximize()">
                <CIcon
                    name="window-maximize"
                    height="23"
                    width="23"
                    fill/>
            </button>
            <button 
                v-if="!dialog.options.hideCloseButton"
                @click.prevent="() => removeModal(dialog)">
                <CIcon
                    name="window-close"
                    height="23"
                    width="23"
                    fill/>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dialogService from './dialog.service';

import Modal from './Modal.vue';

export default defineComponent({
    name: 'ModalHost',
    components: {
        Modal,
    },
    setup() {
        const { dialogs, minimizedDialogs } = dialogService;

        const removeModal = (dialog: any) => dialogService.removeModal(dialog);

        return {
            dialogs,
            removeModal,
            minimizedDialogs,
        };
    },
});
</script>

<style lang="scss" scoped>
.minimized-dialogs {
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .minimized-dialog {
        background: theme('colors.elements');
        border: 1px solid theme('colors.border');
        padding: 6px 10px;
        flex-shrink: 0;

        animation: blink 0.75s linear;
        animation-iteration-count: 2;

        button {

            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
}

@keyframes blink {
  50% {
    border-color: #F99500;
  }
}
</style>
