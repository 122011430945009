import { App } from 'vue';

const priceFormatters: { [key: string]: { [decimals: number]: Intl.NumberFormat } } = {};
const numberFormatters: { [key: string]: { [decimals: number]: Intl.NumberFormat } } = {};

export function formatPrice(amount: number, currency: string, decimals = 0): string {
    if (isNaN(amount)) {
        return amount as unknown as any;
    }

    const formattersForCurrency = priceFormatters[window.languageCode] ?? {};
    let formatter: Intl.NumberFormat | undefined = formattersForCurrency[decimals];

    if (!formatter) {
        formatter = new Intl.NumberFormat(window.languageCode, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        });

        formattersForCurrency[decimals] = formatter;
        priceFormatters[window.languageCode] = formattersForCurrency;
    }

    return formatter.format(amount).replace('\xa0', ' ');
}

export function formatNumber(amount: number, decimals = 0): string {
    if (isNaN(amount)) {
        return amount as unknown as any;
    }

    const formattersForCurrency = numberFormatters[window.languageCode] ?? {};
    let formatter: Intl.NumberFormat | undefined = formattersForCurrency[decimals];

    if (!formatter) {
        formatter = new Intl.NumberFormat(window.languageCode, {
            style: 'decimal',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        });

        formattersForCurrency[decimals] = formatter;
        numberFormatters[window.languageCode] = formattersForCurrency;
    }

    return formatter.format(amount).replace('\xa0', ' ');
}

export function formatPhoneNumber(phoneNumber: string): string {
    // Remove all non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Use regular expressions to add spaces in the desired format
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
  
    return formattedPhoneNumber;
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $priceFormat: (amount: number, currency: string, decimals?: number) => string;
        $numberFormat: (amount: number, decimals?: number) => string;
        $phoneFormat: (phoneNumber: string) => string;
    }
}

export default function config(app: App): void {
    app.config.globalProperties.$priceFormat = formatPrice;
    app.config.globalProperties.$numberFormat = formatNumber;
    app.config.globalProperties.$phoneFormat = formatPhoneNumber;
}
