<template>
    <div ref="rootEl">
        <div v-if="hasLeadingGuards" :tabIndex="disabled ? -1 : 0" :style="hidden" aria-hidden="true"></div>
        <div v-if="hasLeadingGuards" :tabIndex="disabled ? -1 : 1" :style="hidden" aria-hidden="true"></div>

        <div v-bind="groupAttr" data-lock class="h-full w-full" @focusout="onBlur">
            <slot></slot>
        </div>

        <div v-if="hasTailingGuards" :tabIndex="disabled ? -1 : 0" :style="hidden" aria-hidden="true"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, getCurrentInstance, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import moveFocusInside, { focusInside, focusIsHidden, constants } from 'focus-lock';

let lastActiveTrap: any = null;
let lastActiveFocus: any = null;
let focusWasOutsideWindow = false;

const focusOnBody = () => (
    document && document.activeElement === document.body
);

const isFreeFocus = () => focusOnBody() || focusIsHidden();

const activateTrap = () => {
    let result = false;

    if (lastActiveTrap) {
        const { observed, onActivation } = lastActiveTrap;
        if (focusWasOutsideWindow || !isFreeFocus() || !lastActiveFocus) {
            if (observed && !focusInside(observed)) {
                onActivation();
                moveFocusInside(observed, lastActiveFocus, { focusOptions: { preventScroll: true }});
                result = true;
            }
            focusWasOutsideWindow = false;
            lastActiveFocus = document && document.activeElement;
        }
    }

    return result;
};

const reducePropsToState = (propsList) => {
    return propsList
        .filter(({ disabled }) => !disabled)
        .slice(-1)[0];
};

const handleStateChangeOnClient = (trap) => {
    if (lastActiveTrap !== trap) {
        lastActiveTrap = null;
    }
    lastActiveTrap = trap;
    if (trap) {
        activateTrap();
        setTimeout(activateTrap, 1);
    }
};

let instances: any[] = [];

const emitChange = () => {
    handleStateChangeOnClient(reducePropsToState(instances));
};

const onTrap = (event) => {
    if (activateTrap() && event) {
        // prevent scroll jump
        event.stopPropagation();
        event.preventDefault();
    }
};

const onBlur = () => {
    setTimeout(activateTrap, 1);
};

const onWindowBlur = () => {
    focusWasOutsideWindow = true;
};

const attachHandler = () => {
    document.addEventListener('focusin', onTrap, true);
    document.addEventListener('focusout', onBlur);
    window.addEventListener('blur', onWindowBlur);
};

const detachHandler = () => {
    document.removeEventListener('focusin', onTrap, true);
    document.removeEventListener('focusout', onBlur);
    window.removeEventListener('blur', onWindowBlur);
};

export default defineComponent({
    name: 'Lock',
    props: {
        returnFocus: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        noFocusGuards: {
            type: [Boolean, String],
            default: false,
        },
        group: {
            type: String,
        },
    },
    setup(props) {
        const { returnFocus, disabled, noFocusGuards, group } = toRefs(props);
        const rootEl = ref<HTMLElement | null>(null);
        const data = ref<any>({});
        const hidden = ref(''); //    "width: 1px;height: 0px;padding: 0;overflow: hidden;position: fixed;top: 0;left: 0;"

        const groupAttr = computed(() => {
            return { [constants.FOCUS_GROUP]: group.value };
        });

        const hasLeadingGuards = computed(() => {
            return noFocusGuards.value !== true;
        });

        const hasTailingGuards = computed(() => {
            return hasLeadingGuards.value && (noFocusGuards.value !== 'tail');
        });

        watch(disabled, () => {
            data.value.disabled = disabled.value;
            emitChange();
        });

        let originalFocusedElement;

        onMounted(() => {
            data.value.vue = getCurrentInstance();
            data.value.observed = rootEl.value?.querySelector('[data-lock]');
            data.value.disabled = disabled.value;
            data.value.onActivation = () => {
                originalFocusedElement = originalFocusedElement || (document && document.activeElement);
            };
            if (!instances.length) {
                attachHandler();
            }
            instances.push(data.value);
            emitChange();
        });

        onUnmounted(() => {
            instances = instances.filter(({ vue }) => vue !== getCurrentInstance());
            if (!instances.length) {
                detachHandler();
            }
            if (
                returnFocus.value && originalFocusedElement && originalFocusedElement.focus
            ) {
                originalFocusedElement.focus();
            }
            emitChange();
        });

        return {
            groupAttr,
            hasLeadingGuards,
            hasTailingGuards,
            hidden,
            onBlur: () => setTimeout(emitChange, 1),
            rootEl,
        };
    },
});
</script>
