<template>
    <div
        class="hu-color-picker"
        :class="{ light: isLightTheme }"
        :style="{ width: totalWidth + 'px' }">
        <div class="color-set">
            <Saturation
                ref="saturation"
                :color="rgbString"
                :hsv="hsv"
                :size="hueHeight"
                @select-saturation="selectSaturation"/>
            <Hue
                ref="hue"
                :hsv="hsv"
                :width="hueWidth"
                :height="hueHeight"
                @select-hue="selectHue"/>
            <Alpha
                ref="alpha"
                :color="rgbString"
                :rgba="rgba"
                :width="hueWidth"
                :height="hueHeight"
                @select-alpha="selectAlpha"/>
        </div>
        <div :style="{ height: previewHeight + 'px' }" class="color-show">
            <Preview
                :color="rgbaString"
                :width="previewWidth"
                :height="previewHeight"/>
        </div>
        <Box name="HEX" :color="modelHex" @input-color="inputHex"/>
        <Box name="RGBA" :color="modelRgba" @input-color="inputRgba"/>
        <Colors
            :color="rgbaString"
            :colors-default="colorsDefault"
            @select-color="selectColor"/>
        <!-- custom options -->
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { setColorValue, rgb2hex } from './composible';

import Saturation from './Saturation.vue';
import Hue from './Hue.vue';
import Alpha from './Alpha.vue';
import Preview from './Preview.vue';
import Box from './Box.vue';
import Colors from './Colors.vue';

export default defineComponent({
    components: {
        Saturation,
        Hue,
        Alpha,
        Preview,
        Box,
        Colors,
    },
    emits: ['change-color', 'update:modelValue'],
    props: {
        modelValue: {
            type: String,
            default: '#000000',
        },
        theme: {
            type: String as PropType<'light' | 'dark'>,
            default: 'dark',
        },
        colorsDefault: {
            type: Array as PropType<string[]>,
            default: () => [
                '#000000',
                '#FFFFFF',
                '#FF1900',
                '#F47365',
                '#FFB243',
                '#FFE623',
                '#6EFF2A',
                '#1BC7B1',
                '#004c6e',
                '#00BEFF',
                '#2E81FF',
                '#5D61FF',
                '#FF89CF',
                '#FC3CAD',
                '#BF3DCE',
                '#8E00A7',
            ],
        },
    },
    data() {
        return {
            hueWidth: 15,
            hueHeight: 152,
            previewHeight: 25,
            modelRgba: '',
            modelHex: '',
            red: 0,
            green: 0,
            blue: 0,
            alpha: 1,
            h: 0,
            s: 0,
            v: 0,
        };
    },
    computed: {
        isLightTheme(): boolean {
            return this.theme === 'light';
        },
        totalWidth(): number {
            return this.hueHeight + (this.hueWidth + 19) * 2;
        },
        previewWidth(): number {
            return this.totalWidth - 10 * 2;
        },
        rgba() {
            return {
                red: this.red,
                green: this.green,
                blue: this.blue,
                alpha: this.alpha,
            };
        },
        hsv() {
            return {
                h: this.h,
                s: this.s,
                v: this.v,
            };
        },
        rgbString(): string {
            return `rgb(${this.red}, ${this.green}, ${this.blue})`;
        },
        rgbaStringShort(): string {
            return `${this.red}, ${this.green}, ${this.blue}, ${this.alpha}`;
        },
        rgbaString(): string {
            return `rgba(${this.rgbaStringShort})`;
        },
        hexString(): string {
            return rgb2hex(this.rgba, true);
        },
    },
    created() {
        Object.assign(this, setColorValue(this.modelValue));
        this.setText();

        this.$watch('rgba', () => {
            this.$emit('change-color', {
                rgba: this.rgba,
                hsv: this.hsv,
                hex: this.modelHex,
            });

            this.$emit('update:modelValue', this.hexString);
        });
    },
    methods: {
        selectSaturation(color: any) {
            const { red, green, blue, h, s, v } = setColorValue(color);
            Object.assign(this, { red, green, blue, h, s, v });
            this.setText();
        },
        selectHue(color: any) {
            const { red, green, blue, h, s, v } = setColorValue(color);
            Object.assign(this, { red, green, blue, h, s, v });
            this.setText();
            this.$nextTick(() => {
                (this.$refs as any).saturation.renderColor();
                (this.$refs as any).saturation.renderSlide();
            });
        },
        selectAlpha(alpha: number) {
            this.alpha = alpha;
            this.setText();
        },
        inputHex(color: string) {
            const { red, green, blue, alpha, h, s, v } = setColorValue(color);
            Object.assign(this, { red, green, blue, alpha, h, s, v });
            this.modelHex = color;
            this.modelRgba = this.rgbaStringShort;
            this.$nextTick(() => {
                (this.$refs as any).saturation.renderColor();
                (this.$refs as any).saturation.renderSlide();
                (this.$refs as any).hue.renderSlide();
            });
        },
        inputRgba(color: string) {
            const { red, green, blue, alpha, h, s, v } = setColorValue(color);
            Object.assign(this, { red, green, blue, alpha, h, s, v });
            this.modelHex = this.hexString;
            this.modelRgba = color;
            this.$nextTick(() => {
                (this.$refs as any).saturation.renderColor();
                (this.$refs as any).saturation.renderSlide();
                (this.$refs as any).hue.renderSlide();
            });
        },
        setText() {
            this.modelHex = this.hexString;
            this.modelRgba = this.rgbaStringShort;
        },
        selectColor(color: string) {
            const { red, green, blue, alpha, h, s, v } = setColorValue(color);
            Object.assign(this, { red, green, blue, alpha, h, s, v });
            this.setText();
            this.$nextTick(() => {
                (this.$refs as any).saturation.renderColor();
                (this.$refs as any).saturation.renderSlide();
                (this.$refs as any).hue.renderSlide();
            });
        },
    },
});
</script>

<style lang="scss">
.hu-color-picker {
  padding: 10px;
  background: #1d2024;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  &.light {
    background: #f7f8f9;
    .color-show {
      .sucker {
        background: #eceef0;
      }
    }
    .color-type {
      .name {
        background: #e7e8e9;
      }
      .value {
        color: #666;
        background: #eceef0;
      }
    }
    .colors.history {
      border-top: 1px solid #eee;
    }
  }
  canvas {
    vertical-align: top;
  }
  .color-set {
    display: flex;
  }
  .color-show {
    margin-top: 8px;
    display: flex;
  }
}
</style>
