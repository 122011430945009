import { App } from 'vue';
import { QuillEditor, Quill } from '@vueup/vue-quill';
import ImageResize from '@taoqf/quill-image-resize-module';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const pixelSizes = Array.from({ length: (20 - 10) + 1 }, (_, i) => (i + 10) + 'px');

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // ['blockquote', 'code-block'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

    [{ align: '' }, { align: 'center' }, { align: 'right' }],

    [{ 'color': [] }, { 'background': [] }, { 'size': pixelSizes }],          // dropdown with defaults from theme
    
    ['clean'], // remove formatting button
];

const globalOptions = {
    modules: {
        toolbar: toolbarOptions,
        imageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
        },
    },
    theme: 'snow',
};

const size = Quill.import('attributors/style/size');
size.whitelist = pixelSizes;
Quill.register(size, true);
Quill.register('modules/imageResize', ImageResize);

QuillEditor.props.globalOptions.default = () => globalOptions;

export default function config(app: App): void {
    app.component('Editor', QuillEditor);
}
