import { ref, Ref } from 'vue';

declare global {
    interface Window {
        scrollbarWidth: Ref<number>
    }
}

export function isIpone(): boolean {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
    ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

export function isTouchDevice(): boolean {
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
}

export function getScrollbarWidth(): number {
    if (isIpone()) {
        return 0;
    }

    // Creating invisible container
    const outer = document.createElement('div') as any;
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}

export function initDomVariables(): void {
    window.scrollbarWidth ??= ref(0);
    window.scrollbarWidth.value = getScrollbarWidth();
}

window.addEventListener('resize', () => requestAnimationFrame(initDomVariables));

export function getDecimalSeparator(locale?: string) {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale)!.formatToParts(numberWithDecimalSeparator)!.find(part => part.type === 'decimal')!.value;
}

export function getInvalidDecimalSeparator(locale?: string) {
    return getDecimalSeparator(locale) === '.' ? ',' : '.';
}