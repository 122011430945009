export interface IDeferred<T> {
    resolve(value?: T): void;
    reject(value?: T): void;
    get isSettled(): boolean;
    get promise(): Promise<T>;
}

export default class Deferred<T = void> {
    private _resolve!: (value?: (PromiseLike<T> | T)) => void;
    private _reject!: (value?: (PromiseLike<T> | T)) => void;
    private _isSettled = false;

    private _promise: Promise<T | undefined> = new Promise<T | undefined>((resolve, reject) => {
        this._reject = reject;
        this._resolve = resolve;
    });

    public get promise(): Promise<T | undefined> {
        return this._promise;
    }

    public resolve(value?: T): void {
        this._resolve(value);
        this._isSettled = true;
    }

    public reject(value?: T): void {
        this._reject(value);
        this._isSettled = true;
    }

    public get isSettled(): boolean {
        return this._isSettled;
    }
}
