import { Locale, setDefaultOptions } from 'date-fns';

import { da } from 'date-fns/locale';

export const defaultOptions: { locale?: Locale, weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 } = {
    locale: da,
    weekStartsOn: 1,
};

export default function config() {
    setDefaultOptions(defaultOptions);
}