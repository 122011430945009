import { useBreakpoints } from '@vueuse/core';

import tailwindConfig from './tailwind.config';

export type BreakPoints = {
    [Key in keyof typeof tailwindConfig.theme.screens]: number;
};

const parseScreenSizes = (screens: Record<string, string>): BreakPoints => {
    const parsedScreens: Partial<BreakPoints> = {};

    for (const [key, value] of Object.entries(screens)) {
        // Parse only the numerical part of the screen size (e.g., '576px' -> 576)
        parsedScreens[key as keyof BreakPoints] = parseInt(value, 10);
    }

    return parsedScreens as BreakPoints;
};

export const screens: BreakPoints = parseScreenSizes(tailwindConfig.theme.screens);

export const breakpoints = useBreakpoints(screens);
