import { computed, ComputedRef } from 'vue';

export type TransitionType = 'fade' | 'slide' | 'zoom' | 'rotate' | 'flip' | 'bounce' | 'back';
export type TransitionDirection = '' | 'Down' | 'DownBig' | 'Left' | 'LeftBig' | 'Right' | 'RightBig' | 'Up' | 'UpBig' | 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight';
export type TransitionDuration = '200ms' | '250ms' | '300ms' | '500ms' | '1000ms';

export function useAnimation({ type, enterDirection = '', leaveDirection = '', duration = '300ms' }: {
    type: TransitionType, enterDirection?: TransitionDirection, leaveDirection?: TransitionDirection, duration?: TransitionDuration
}): {
    enterActiveClass: ComputedRef<string>,
    leaveActiveClass: ComputedRef<string>
} {
    return {
        enterActiveClass: computed<string>(() => {
            switch (type) {
            case 'slide':
            case 'back':
                return `animate__animated animate__${type}In${enterDirection || 'Left'} animate__duration_${duration.slice(0, -2)}`;
            default:
                return `animate__animated animate__${type}In${enterDirection} animate__duration_${duration.slice(0, -2)}`;
            }
        }),
        leaveActiveClass: computed<string>(() => {
            switch (type) {
            case 'slide':
            case 'back':
                return `animate__animated animate__${type}Out${leaveDirection || 'Right'} animate__duration_${duration.slice(0, -2)}`;
            default:
                return `animate__animated animate__${type}Out${leaveDirection} animate__duration_${duration.slice(0, -2)}`;
            }
        }),
    };
}
