export function setColorValue(color: string) {
    let rgba: any = { red: 0, green: 0, blue: 0, alpha: 1 };
    if (/#/.test(color)) {
        rgba = hex2rgb(color);
    } else if (/rgb/.test(color)) {
        rgba = rgb2rgba(color);
    } else if (typeof color === 'string') {
        rgba = rgb2rgba(`rgba(${color})`);
    } else if (Object.prototype.toString.call(color) === '[object Object]') {
        rgba = color;
    }
    const { red, green, blue, alpha } = rgba;
    const { h, s, v } = rgb2hsv(rgba);
    return { red, green, blue, alpha: alpha === undefined ? 1 : alpha, h, s, v };
}

export function createAlphaSquare(size: number) {
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');
    const doubleSize = size * 2;

    canvas.width = doubleSize;
    canvas.height = doubleSize;

    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, doubleSize, doubleSize);
    ctx.fillStyle = '#ccd5db';
    ctx.fillRect(0, 0, size, size);
    ctx.fillRect(size, size, size, size);

    return canvas;
}

export function createLinearGradient(
    direction: any,
    ctx: any,
    width: any,
    height: any,
    color1: any,
    color2: any,
) {
    // l horizontal p vertical
    const isL = direction === 'l';
    const gradient = ctx.createLinearGradient(
        0,
        0,
        isL ? width : 0,
        isL ? 0 : height,
    );
    gradient.addColorStop(0.01, color1);
    gradient.addColorStop(0.99, color2);
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);
}

export function rgb2hex({ red, green, blue }: { red: number, green: number, blue: number }, toUpper: boolean = true) {
    const change = (val: any) => ('0' + Number(val).toString(16)).slice(-2);
    const color = `#${change(red)}${change(green)}${change(blue)}`;
    return toUpper ? color.toUpperCase() : color;
}

export function hex2rgb(hex: string) {
    hex = hex.slice(1);
    const change = (val: string) => parseInt(val, 16) || 0; // Avoid NaN situations
    return {
        red: change(hex.slice(0, 2)),
        green: change(hex.slice(2, 4)),
        blue: change(hex.slice(4, 6)),
    };
}

export function rgb2rgba(rgba: string | { red: number, green: number, blue: number }) {
    if (typeof rgba === 'string') {
        const converted = (/rgba?\((.*?)\)/.exec(rgba) || ['', '0,0,0,1'])[1].split(',');
        return {
            red: Number(converted[0]) || 0,
            green: Number(converted[1]) || 0,
            blue: Number(converted[2]) || 0,
            alpha: Number(converted[3] ? converted[3] : 1), // Avoid the case of 0
        };
    } else {
        return rgba;
    }
}

export function rgb2hsv({ red, green, blue }: { red: number, green: number, blue: number }) {
    red = red / 255;
    green = green / 255;
    blue = blue / 255;
    const max = Math.max(red, green, blue);
    const min = Math.min(red, green, blue);
    const delta = max - min;
    let h = 0;
    if (max === min) {
        h = 0;
    } else if (max === red) {
        if (green >= blue) {
            h = (60 * (green - blue)) / delta;
        } else {
            h = (60 * (green - blue)) / delta + 360;
        }
    } else if (max === green) {
        h = (60 * (blue - red)) / delta + 120;
    } else if (max === blue) {
        h = (60 * (red - green)) / delta + 240;
    }
    h = Math.floor(h);
    const s = parseFloat((max === 0 ? 0 : 1 - min / max).toFixed(2));
    const v = parseFloat(max.toFixed(2));
    return { h, s, v };
}
