<template>
    <div ref="container"
         class="splitpanes"
         :class="{ 
             horizontal: horizontal,
             vertical: !horizontal,
             'splitpanes--dragging': touch.dragging
         }">
        <slot :equalize="equalize"/>
    </div>
</template>

<script lang="ts">
import { defineComponent, CSSProperties } from 'vue';

interface Pane {
    id: string;
    min: number;
    max: number;
    size: number;
    givenSize: number;
    index: number;
    last: boolean;
    update: (style: CSSProperties, last: boolean, active: boolean) => void;
}

export default defineComponent({
    name: 'Splitpanes',

    provide() {
        return {
            requestUpdate: this.requestUpdate,
            onPaneAdd: this.onPaneAdd,
            onPaneRemove: this.onPaneRemove,
        };
    },

    props: {
        horizontal: { type: Boolean },
        pushOtherPanes: { type: Boolean, default: true },
        rtl: { type: Boolean, default: false }, // Right to left direction.
    },
    emits: ['ready', 'resized', 'pane-click', 'pane-maximize', 'pane-add', 'pane-remove', 'splitter-click'],

    data: () => ({
        container: null!,
        ready: false,
        panes: [],
        touch: {
            mouseDown: false,
            dragging: false,
            activePaneIndex: -1,
        },
        splitterTaps: { // Used to detect double click on touch devices.
            splitter: null,
            timeoutId: null,
        },
    } as { 
        panes: Pane[],
        container: HTMLDivElement,
        ready: boolean,
        touch: {
            mouseDown: boolean,
            dragging: boolean,
            activePaneIndex: number
        },
        splitterTaps: {
            splitter: any,
            timeoutId: any
        }
    }),

    computed: {
        panesCount() {
            return this.panes.length;
        },
        // Indexed panes by `uid` of Pane components for fast lookup.
        // Every time a pane is destroyed this index is recomputed.
        indexedPanes() {
            return this.panes.reduce((obj, pane) => (obj[pane.id] = pane) && obj, {});
        },
    },

    watch: {
        panes: { // Every time a pane is updated, update the panes accordingly.
            deep: true,
            immediate: false,
            handler() { this.updatePaneComponents(); },
        },
        horizontal() {
            this.updatePaneComponents();
        },
    },

    beforeUnmount() {
        // Prevent emitting console warnings on hot reloading.
        this.ready = false;
    },

    mounted() {
        this.container = this.$refs.container as HTMLDivElement;
        this.checkSplitpanesNodes();
        this.redoSplitters();
        this.resetPaneSizes();

        setTimeout(() => {
            this.emitPaneResized();
        });

        this.ready = true;
    },

    methods: {
        emitPaneResized() {
            const containerSize = this.container[this.horizontal ? 'clientHeight' : 'clientWidth'];
            this.$emit('resized', this.panes.map(pane => ({ min: pane.min, max: pane.max, size: pane.size, sizeInPixel: (containerSize/100)*pane.size })));
        },
        
        updatePaneComponents() {
            // On update refresh the size of each pane through the registered `update` method (in onPaneAdd).
            this.panes.forEach((pane, index) => {
                if (pane) {
                    pane.update({
                        // Panes are indexed by Pane component uid, as they might be inserted at different index.
                        [this.horizontal ? 'height' : 'width']: `${this.indexedPanes[pane.id].size}%`,
                        pointerEvents: this.touch.activePaneIndex >= 0 ? this.touch.activePaneIndex === index ? 'auto' : 'none' : 'auto',
                    },
                    index === this.panes.length - 1,
                    this.touch.activePaneIndex === index);
                }
            });
        },

        onMouseDown(event, activePaneIndex: number) {
            this.bindEvents();
            this.touch.mouseDown = true;
            this.touch.activePaneIndex = activePaneIndex;
        },

        bindEvents() {
            document.addEventListener('mousemove', this.onMouseMove, { passive: false });
            document.addEventListener('mouseup', this.onMouseUp);

            // Passive: false to prevent scrolling while touch dragging.
            if ('ontouchstart' in window) {
                document.addEventListener('touchmove', this.onMouseMove, { passive: false });
                document.addEventListener('touchend', this.onMouseUp);
            }
        },

        onMouseUp() {
            if (this.touch.dragging) {
                this.emitPaneResized();
            }
            this.touch.mouseDown = false;
            this.touch.dragging = false;
            this.touch.activePaneIndex = -1;
            this.unbindEvents();
            this.updatePaneComponents();
        },

        unbindEvents() {
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('mouseup', this.onMouseUp);

            if ('ontouchstart' in window) {
                document.removeEventListener('touchmove', this.onMouseMove);
                document.removeEventListener('touchend', this.onMouseUp);
            }
        },

        onMouseMove(event) {
            if (this.touch.mouseDown) {
                // Prevent scrolling while touch dragging (only works with an active event, eg. passive: false).
                event.preventDefault();
                this.touch.dragging = true;
                this.calculatePanesSize(this.getCurrentMouseDrag(event));
                this.emitPaneResized();
            }
        },

        // If touch device, detect double tap manually (2 taps separated by less than 500ms).
        onSplitterClick(event, activePaneIndex) {
            if ('ontouchstart' in window) {
                event.preventDefault();
            }

            if (!this.touch.dragging) {
                this.$emit('splitter-click', this.panes[activePaneIndex]);
            }
        },

        // On splitter dbl click or dbl tap maximize this pane.
        onSplitterDblClick(event, activePaneIndex) {
            let totalMinSizes = 0;
            this.panes = this.panes.map((pane, i) => {
                pane.size = i === activePaneIndex ? pane.max : pane.min;
                if (i !== activePaneIndex) totalMinSizes += pane.min;

                return pane;
            });
            this.panes[activePaneIndex].size -= totalMinSizes;
            this.$emit('pane-maximize', this.panes[activePaneIndex]);
            this.emitPaneResized();
        },

        // Get the cursor position relative to the splitpane container.
        getCurrentMouseDrag(event) {
            const rect = this.container.getBoundingClientRect();
            const { clientX, clientY } = ('ontouchstart' in window && event.touches) ? event.touches[0] : event;

            return {
                x: clientX - rect.left,
                y: clientY - rect.top,
            };
        },

        // Returns the drag percentage of the splitter relative to the 2 panes it's inbetween.
        // if the sum of size of the 2 cells is 60%, the dragPercentage range will be 0 to 100% of this 60%.
        getCurrentDragPercentage(drag: { x: number, y: number }) {
            let position = drag[this.horizontal ? 'y' : 'x'];
            // In the code bellow 'size' refers to 'width' for vertical and 'height' for horizontal layout.
            const containerSize = this.container[this.horizontal ? 'clientHeight' : 'clientWidth'];
            if (this.rtl && !this.horizontal) {
                position = containerSize - position;
            }

            return position * 100 / containerSize;
        },

        calculatePanesSize(drag: { x: number, y: number }) {
            const activePaneIndex = this.touch.activePaneIndex;
            
            let sums = {
                prevPanesSize: this.sumPrevPanesSize(activePaneIndex),
                nextPanesSize: this.sumNextPanesSize(activePaneIndex),
                prevReachedMinPanes: 0,
                nextReachedMinPanes: 0,
            };

            const minDrag = 0 + (this.pushOtherPanes ? 0 : sums.prevPanesSize);
            const maxDrag = 100 - (this.pushOtherPanes ? 0 : sums.nextPanesSize);
            const dragPercentage = Math.max(Math.min(this.getCurrentDragPercentage(drag), maxDrag), minDrag);

            // If not pushing other panes, panes to resize are right before and right after splitter.
            let panesToResize = [activePaneIndex, activePaneIndex + 1];
            let paneBefore = this.panes[panesToResize[0]] || null;
            let paneAfter = this.panes[panesToResize[1]] || null;

            const paneBeforeMaxReached = paneBefore.max < 100 && (dragPercentage >= (paneBefore.max + sums.prevPanesSize));
            const paneAfterMaxReached = paneAfter.max < 100 && (dragPercentage <= 100 - (paneAfter.max + this.sumNextPanesSize(activePaneIndex + 1)));
            // Prevent dragging beyond pane max.
            if (paneBeforeMaxReached || paneAfterMaxReached) {
                if (paneBeforeMaxReached) {
                    paneBefore.size = paneBefore.max;
                    paneAfter.size = Math.max(100 - paneBefore.max - sums.prevPanesSize - sums.nextPanesSize, 0);
                }
                else {
                    paneBefore.size = Math.max(100 - paneAfter.max - sums.prevPanesSize - this.sumNextPanesSize(activePaneIndex + 1), 0);
                    paneAfter.size = paneAfter.max;
                }
                return;
            }

            // When pushOtherPanes = true, find the closest expanded pane on each side of the splitter.
            if (this.pushOtherPanes) {
                const vars = this.doPushOtherPanes(sums, dragPercentage);
                if (!vars) return; // Prevent other calculation.

                ({ sums, panesToResize } = vars);
                paneBefore = this.panes[panesToResize[0]] || null;
                paneAfter = this.panes[panesToResize[1]] || null;
            }

            if (paneBefore !== null) {
                paneBefore.size = Math.min(Math.max(dragPercentage - sums.prevPanesSize - sums.prevReachedMinPanes, paneBefore.min), paneBefore.max);
            }
            if (paneAfter !== null) {
                paneAfter.size = Math.min(Math.max(100 - dragPercentage - sums.nextPanesSize - sums.nextReachedMinPanes, paneAfter.min), paneAfter.max);
            }
        },

        doPushOtherPanes(sums: { prevPanesSize: number, nextPanesSize: number, prevReachedMinPanes: number, nextReachedMinPanes: number }, dragPercentage: number) {
            const activePaneIndex = this.touch.activePaneIndex;

            const panesToResize = [activePaneIndex, activePaneIndex + 1];
            // Pushing Down.
            // Going smaller than the current pane min size: take the previous expanded pane.
            if (dragPercentage < sums.prevPanesSize + this.panes[panesToResize[0]].min) {
                panesToResize[0] = this.findPrevExpandedPane(activePaneIndex)?.index ?? -1;

                sums.prevReachedMinPanes = 0;
                // If pushing a n-2 or less pane, from splitter, then make sure all in between is at min size.
                if (panesToResize[0] < activePaneIndex) {
                    this.panes.forEach((pane, i) => {
                        if (i > panesToResize[0] && i <= activePaneIndex) {
                            pane.size = pane.min;
                            sums.prevReachedMinPanes += pane.min;
                        }
                    });
                }
                sums.prevPanesSize = this.sumPrevPanesSize(panesToResize[0]);
                // If nothing else to push down, cancel dragging.
                if (panesToResize[0] === undefined) {
                    sums.prevReachedMinPanes = 0;
                    this.panes[0].size = this.panes[0].min;
                    this.panes.forEach((pane, i) => {
                        if (i > 0 && i <= activePaneIndex) {
                            pane.size = pane.min;
                            sums.prevReachedMinPanes += pane.min;
                        }
                    });
                    this.panes[panesToResize[1]].size = 100 - sums.prevReachedMinPanes - this.panes[0].min - sums.prevPanesSize - sums.nextPanesSize;
                    return null;
                }
            }
            // Pushing Up.
            // Pushing up beyond min size is reached: take the next expanded pane.
            if (dragPercentage > 100 - sums.nextPanesSize - this.panes[panesToResize[1]].min) {
                panesToResize[1] = this.findNextExpandedPane(activePaneIndex)?.index ?? -1;
                sums.nextReachedMinPanes = 0;
                // If pushing a n+2 or more pane, from splitter, then make sure all in between is at min size.
                if (panesToResize[1] > activePaneIndex + 1) {
                    this.panes.forEach((pane, i) => {
                        if (i > activePaneIndex && i < panesToResize[1]) {
                            pane.size = pane.min;
                            sums.nextReachedMinPanes += pane.min;
                        }
                    });
                }
                sums.nextPanesSize = this.sumNextPanesSize(panesToResize[1] - 1);
                // If nothing else to push up, cancel dragging.
                if (panesToResize[1] === undefined) {
                    sums.nextReachedMinPanes = 0;
                    this.panes[this.panesCount - 1].size = this.panes[this.panesCount - 1].min;
                    this.panes.forEach((pane, i) => {
                        if (i < this.panesCount - 1 && i >= activePaneIndex + 1) {
                            pane.size = pane.min;
                            sums.nextReachedMinPanes += pane.min;
                        }
                    });
                    this.panes[panesToResize[0]].size = 100 - sums.prevPanesSize - sums.nextReachedMinPanes - this.panes[this.panesCount - 1].min - sums.nextPanesSize;
                    return null;
                }
            }
            return { sums, panesToResize };
        },

        sumPrevPanesSize(activePaneIndex: number) {
            return this.panes.reduce((total, pane, i) => total + (i < activePaneIndex ? pane.size : 0), 0);
        },

        sumNextPanesSize(activePaneIndex: number) {
            return this.panes.reduce((total, pane, i) => total + (i > activePaneIndex + 1 ? pane.size : 0), 0);
        },

        // Return the previous pane from siblings which has a size (width for vert or height for horz) of more than 0.
        findPrevExpandedPane(activePaneIndex: number) {
            return [...this.panes].reverse().find(p => (p.index < activePaneIndex && p.size > p.min));
        },

        // Return the next pane from siblings which has a size (width for vert or height for horz) of more than 0.
        findNextExpandedPane(activePaneIndex: number) {
            return this.panes.find(p => (p.index > activePaneIndex + 1 && p.size > p.min));
        },

        checkSplitpanesNodes() {
            const children = Array.from(this.container.children);
            children.forEach(child => {
                const isPane = child.classList.contains('splitpanes__pane');

                // Node is not a Pane or a splitter: remove it.
                if (!isPane) {
                    // eslint-disable-next-line no-console
                    console.warn('Splitpanes: Only <pane> elements are allowed at the root of <splitpanes>. One of your DOM nodes was removed.');
                    return;
                }
            });
        },

        setupSplitter(paneIndex: number, elm: HTMLElement) {
            elm.onmousedown = event => this.onMouseDown(event, paneIndex);

            if (typeof window !== 'undefined' && 'ontouchstart' in window) {
                elm.ontouchstart = event => this.onMouseDown(event, paneIndex);
            }

            elm.onclick = event => this.onSplitterClick(event, paneIndex + 1);
        },

        redoSplitters() {
            const children = Array.from(this.container.children);

            children.forEach((el, paneIndex) => {
                if (el.className.includes('splitpanes__pane')) {
                    const splitter = el.querySelector('.splitpanes__splitter') as HTMLElement;
                    this.setupSplitter(paneIndex, splitter);
                }
            });
        },

        // Called by Pane component on programmatic resize.
        requestUpdate({ target, ...args }) {
            const pane = this.indexedPanes[target._.uid];
            Object.entries(args).forEach(([key, value]) => pane[key] = value);
        },

        onPaneAdd(pane) {
            // 1. Add pane to array at the same index it was inserted in the <splitpanes> tag.
            let index = -1;
            Array.from(pane.$el.parentNode.children as HTMLElement[]).some(el => {
                if (el.className.includes('splitpanes__pane')) index++;
                return el === pane.$el;
            });

            const min = parseFloat(pane.minSize);
            const max = parseFloat(pane.maxSize);

            this.panes.splice(index, 0, {
                id: pane._.uid,
                index,
                min: isNaN(min) ? 0 : min,
                max: isNaN(max) ? 100 : max,
                size: parseFloat(pane.size),
                givenSize: parseFloat(pane.size),
                update: pane.update,
                last: true,
            });

            // Redo indexes after insertion for other shifted panes.
            this.panes.forEach((p, i) => {
                p.index = i;
                p.last = i === this.panes.length - 1;
            });

            if (this.ready) {
                this.$nextTick(() => {
                    // 2. Add the splitter.
                    this.redoSplitters();

                    // 3. Resize the panes.
                    this.resetPaneSizes({ addedPane: this.panes[index] });

                    // 4. Fire `pane-add` event.
                    this.$emit('pane-add', { index, panes: this.panes.map(pane => ({ min: pane.min, max: pane.max, size: pane.size })) });
                });
            }
        },

        onPaneRemove(pane) {
            // 1. Remove the pane from array and redo indexes.
            const index = this.panes.findIndex(p => p.id === pane._.uid);
            const removed = this.panes.splice(index, 1)[0];
            this.panes.forEach((p, i) => p.index = i);

            this.$nextTick(() => {
                // 2. Remove the splitter.
                this.redoSplitters();

                // 3. Resize the panes.
                this.resetPaneSizes({ removedPane: { ...removed, index } });

                // 4. Fire `pane-remove` event.
                this.$emit('pane-remove', { removed, panes: this.panes.map(pane => ({ min: pane.min, max: pane.max, size: pane.size })) });
            });
        },

        resetPaneSizes(changedPanes?: { addedPane?: Pane, removedPane?: Pane }) {
            if (!changedPanes?.addedPane && !changedPanes?.removedPane) {
                this.initialPanesSizing();
            }
            else if (this.panes.some(pane => pane.givenSize !== null || pane.min || pane.max < 100)) {
                this.equalizeAfterAddOrRemove(changedPanes);
            }
            else {
                this.equalize();
            }

            if (this.ready) {
                const containerSize = this.container[this.horizontal ? 'clientHeight' : 'clientWidth'];
                this.$emit('resized', this.panes.map(pane => ({ min: pane.min, max: pane.max, size: pane.size, sizeInPixel: (containerSize/100)*pane.size })));
            }
        },

        equalize() {
            const equalSpace = 100 / this.panesCount;
            let leftToAllocate = 0;
            let ungrowable: string[] = [];
            let unshrinkable: string[] = [];

            this.panes.forEach(pane => {
                pane.size = Math.max(Math.min(equalSpace, pane.max), pane.min);

                leftToAllocate -= pane.size;
                if (pane.size >= pane.max) ungrowable.push(pane.id);
                if (pane.size <= pane.min) unshrinkable.push(pane.id);
            });

            if (leftToAllocate > 0.1) this.readjustSizes(leftToAllocate, ungrowable, unshrinkable);

            if (this.ready) {
                const containerSize = this.container[this.horizontal ? 'clientHeight' : 'clientWidth'];
                this.$emit('resized', this.panes.map(pane => ({ min: pane.min, max: pane.max, size: pane.size, sizeInPixel: (containerSize/100)*pane.size })));
            }
        },

        initialPanesSizing() {
            let leftToAllocate = 100;
            let ungrowable: string[] = [];
            let unshrinkable: string[] = [];

            // Check if pre-allocated space is 100%.
            this.panes.forEach(pane => {
                leftToAllocate -= pane.size;
                if (pane.size >= pane.max) ungrowable.push(pane.id);
                if (pane.size <= pane.min) unshrinkable.push(pane.id);
            });

            // set pane sizes if not set.
            if (leftToAllocate > 0.1 || leftToAllocate < -0.1) {
                this.readjustSizes(leftToAllocate, ungrowable, unshrinkable);
            }
        },

        equalizeAfterAddOrRemove({ addedPane }: { addedPane?: Pane, removedPane?: Pane }) {
            let equalSpace = 100 / this.panesCount;
            let leftToAllocate = 0;
            let ungrowable: string[] = [];
            let unshrinkable: string[] = [];

            if (addedPane && addedPane.givenSize !== null) {
                equalSpace = (100 - addedPane.givenSize) / (this.panesCount - 1);
            }

            // Check if pre-allocated space is 100%.
            this.panes.forEach(pane => {
                leftToAllocate -= pane.size;
                if (pane.size >= pane.max) ungrowable.push(pane.id);
                if (pane.size <= pane.min) unshrinkable.push(pane.id);
            });

            if (Math.abs(leftToAllocate) < 0.1) return; // Ok.

            this.panes.forEach(pane => {
                // eslint-disable-next-line no-empty
                if (addedPane && addedPane.givenSize !== null && addedPane.id === pane.id) {}
                else pane.size = Math.max(Math.min(equalSpace, pane.max), pane.min);

                leftToAllocate -= pane.size;
                if (pane.size >= pane.max) ungrowable.push(pane.id);
                if (pane.size <= pane.min) unshrinkable.push(pane.id);
            });

            if (leftToAllocate > 0.1) this.readjustSizes(leftToAllocate, ungrowable, unshrinkable);
        },

        // Second loop to adjust sizes now that we know more about the panes constraints.
        readjustSizes(leftToAllocate: number, ungrowable: string[], unshrinkable: string[]) {
            if (!this.panes.length) return;

            let equalSpaceToAllocate: number;
            if (leftToAllocate > 0) equalSpaceToAllocate = leftToAllocate / (this.panesCount - ungrowable.length);
            else equalSpaceToAllocate = leftToAllocate / (this.panesCount - unshrinkable.length);

            this.panes.forEach((pane, index) => {
                if (leftToAllocate > 0 && !ungrowable.includes(pane.id)) {
                    // Need to diff the size before and after to get the exact allocated space.
                    const newPaneSize = Math.max(Math.min(pane.size + equalSpaceToAllocate, pane.max), pane.min);
                    const allocated = newPaneSize - pane.size;
                    leftToAllocate -= allocated;
                    pane.size = newPaneSize;
                }
                else if (!unshrinkable.includes(pane.id)) {
                    // Need to diff the size before and after to get the exact allocated space.
                    const newPaneSize = Math.max(Math.min(pane.size + equalSpaceToAllocate, pane.max), pane.min);
                    const allocated = newPaneSize - pane.size;
                    leftToAllocate -= allocated;
                    pane.size = newPaneSize;
                }

                // Update each pane through the registered `update` method.
                pane.update({
                    [this.horizontal ? 'height' : 'width']: `${this.indexedPanes[pane.id].size}%`,
                }, 
                index === this.panes.length - 1,
                this.touch.activePaneIndex === index);
            });

            if (Math.abs(leftToAllocate) > 0.1) { // > 0.1: Prevent maths rounding issues due to bytes.
                // Don't emit on hot reload when Vue destroys panes.
                this.$nextTick(() => {
                    if (this.ready) {
                        // eslint-disable-next-line no-console
                        console.warn('Splitpanes: Could not resize panes correctly due to their constraints.');
                    }
                });
            }
        },
    },
});
</script>

<style lang="scss">
.splitpanes {
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  &--vertical {flex-direction: row;}
  &--horizontal {flex-direction: column;}
  &--dragging * {user-select: none;}

  &__pane {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .splitpanes--vertical & {transition: width 0.2s ease-out;}
    .splitpanes--horizontal & {transition: height 0.2s ease-out;}
    .splitpanes--dragging & {transition: none;}
  }

  // Disable default zoom behavior on touch device when double tapping splitter.
  &__splitter {touch-action: none;}
  &--vertical > .splitpanes__pane .splitpanes__splitter {min-width: 1px;cursor: col-resize;}
  &--horizontal > .splitpanes__pane .splitpanes__splitter {min-height: 1px;cursor: row-resize;}
}
.splitpanes.default-theme {
  .splitpanes__pane {
    background-color: #f2f2f2;
  }
  .splitpanes__splitter {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: rgba(0, 0, 0, .15);
      transition: background-color 0.3s;
    }
    &:hover:before, &:hover:after {background-color: rgba(0, 0, 0, .25);}
    &:first-child {cursor: auto;}
  }
}
.default-theme {
  &.splitpanes .splitpanes .splitpanes__splitter {
    z-index: 1;
  }
  &.splitpanes--vertical > .splitpanes__splitter,
  .splitpanes--vertical > .splitpanes__splitter {
    width: 7px;
    border-left: 1px solid #eee;
    margin-left: -1px;
    &:before, &:after {
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
    }
    &:before {margin-left: -2px;}
    &:after {margin-left: 1px;}
  }
  &.splitpanes--horizontal > .splitpanes__splitter,
  .splitpanes--horizontal > .splitpanes__splitter {
    height: 7px;
    border-top: 1px solid #eee;
    margin-top: -1px;
    &:before,
    &:after {
      transform: translateX(-50%);
      width: 30px;
      height: 1px;
    }
    &:before {margin-top: -2px;}
    &:after {margin-top: 1px;}
  }
}
</style>
