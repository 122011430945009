function isEscape(event: KeyboardEvent): boolean {
    return event && (event.key === 'Escape' || event.key === 'Esc');
}

function isDelete(event: KeyboardEvent): boolean {
    return event && (event.key === 'Delete');
}

function isRightArrow(event: KeyboardEvent): boolean {
    return event && (event.key === 'ArrowRight' || event.key === 'Right');
}

function isLeftArrow(event: KeyboardEvent): boolean {
    return event && (event.key === 'ArrowLeft' || event.key === 'Left');
}

function isUpArrow(event: KeyboardEvent): boolean {
    return event && (event.key === 'ArrowUp' || event.key === 'Up');
}

function isDownArrow(event: KeyboardEvent): boolean {
    return event && (event.key === 'ArrowDown' || event.key === 'Down');
}

function isEnter(event: KeyboardEvent): boolean {
    return event && (event.keyCode === 13);
}

function isLeftClick(event: MouseEvent): boolean {
    return event && (event.button === 0 || event.which === 1);
}

function isRightClick(event: MouseEvent): boolean {
    return event && (event.button === 2 || event.which === 3);
}

export default {
    isEscape,
    isDelete,
    isRightArrow,
    isLeftArrow,
    isUpArrow,
    isDownArrow,
    isEnter,
    isLeftClick,
    isRightClick,
};
