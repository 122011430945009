import { App } from 'vue';

import Draggable from 'vuedraggable';
import DraggableResizable from './draggable-resizeable/DraggableResizable';
import DraggableResizableContainer from './draggable-resizeable/DraggableResizableContainer';

export default function config(app: App): void {
    app.component('Draggable', Draggable);
    
    app.component('DraggableResizable', DraggableResizable);
    app.component('DraggableResizableContainer', DraggableResizableContainer);
}
